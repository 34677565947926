<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" v-if="showSitio">
        <h5>Sitio</h5>
        <v-autocomplete
            v-model="filters.sitio_id"
            :items="sitios"
            item-text="nombre"
            item-value="id"
            placeholder="Seleccionar"
            dense
            outlined
            clearable
            @click:clear="getSelectSitio"
            :search-input.sync="searchSitio"
            @keyup="buscarSitio"
        ></v-autocomplete>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" :sm="sm" :md="md" v-if="showDepartamento">
        <h5>Departamento</h5>
        <v-autocomplete
            v-model="filters.departamento_id"
            :items="departamentos"
            item-text="nombre"
            item-value="id"
            placeholder="Seleccionar"
            dense
            outlined
            @change="getSelectMunicipio"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" :sm="sm" :md="md" v-if="showMunicipio">
        <h5>Municipio</h5>
        <v-autocomplete
            v-model="filters.municipio_id"
            :items="municipios"
            item-text="nombre"
            item-value="id"
            placeholder="Seleccionar"
            dense
            outlined
            :disabled="!filters.departamento_id"
            @change="getSelectDistrito"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" :sm="sm" :md="md" v-if="showDistrito">
        <h5>Distrito</h5>
        <v-autocomplete
            v-model="filters.distrito_id"
            :items="distritos"
            item-text="nombre"
            item-value="id"
            placeholder="Seleccionar"
            dense
            outlined
            :disabled="!filters.municipio_id"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6" md="4" v-if="showEstado">
        <h5>Estado</h5>
        <v-autocomplete
            v-model="filters.estado_id"
            :items="estados"
            item-text="nombre"
            item-value="id"
            placeholder="Seleccionar"
            dense
            outlined
        >
          <template #[`item`]="{item}">
            <app-state-table-report :state="item" ></app-state-table-report>
          </template>
          <template #[`selection`]="{item}">
            <app-state-table-report :state="item" ></app-state-table-report>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6" md="4" v-if="isReport && filters.estado_id === 10 || showFechaDesde || $route.name == 'reportesMINED'">
        <h5>Fecha desde</h5>
        <v-menu
            ref="desdeDate"
            v-model="desdeDate"
            :close-on-content-click="true"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="parseDesdeDate"
                placeholder="Fecha desde"
                append-icon="mdi-calendar"
                outlined
                dense
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="fechaDesde"
              no-title
              scrollable
              @input="$refs.desdeDate.save(fechaDesde)"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="6" md="4" v-if="isReport && filters.estado_id === 10 || showFechaHasta || $route.name == 'reportesMINED'">
        <h5>Fecha hasta</h5>
        <v-menu
            ref="hastaDate"
            v-model="hastaDate"
            :close-on-content-click="true"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="parseHastaDate"
                placeholder="Fecha hasta"
                append-icon="mdi-calendar"
                outlined
                dense
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="fechaHasta"
              no-title
              scrollable
              @input="$refs.hastaDate.save(fechaHasta)"
          >
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" :sm="sm" :md="md" class="d-flex align-center mb-2">
        <v-btn
            color="primary"
            class="text-capitalize pa-6 mr-6"
            @click="buscarInforme"
            style="width: 112px"
        >
          {{ btnTextConfirm }}
        </v-btn>

        <v-btn
            color="grey darken-1"
            outlined
            class="text-capitalize pa-6"
            @click="limpiarFilros"
            style="width: 112px"
        >
          {{ btnTextLimpiar }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import AppStateTableReport from "@/components/AppSatateTableReport.vue";

export default {
  name: "filtroComponent",
  components: {AppStateTableReport},
  props: {
    executeClean: {
      type: Function,
      default: () => {
      }
    },
    showDepartamento: {
      type: Boolean,
      default: true
    },
    showMunicipio: {
      type: Boolean,
      default: true
    },
    showDistrito: {
      type: Boolean,
      default: true
    },
    showFechaDesde: {
      type: Boolean,
      default: true
    },
    showFechaHasta: {
      type: Boolean,
      default: true
    },
    showEstado: {
      type: Boolean,
      default: false
    },
    showSitio: {
      type: Boolean,
      default: true
    },
    isReport: {
      type: Boolean,
      default: false
    },
    sm: {
      type: String,
      default: '6'
    },
    md: {
      type: String,
      default: '4'
    },
    btnTextConfirm: {
      type: String,
      default: 'Buscar'
    },
    btnTextLimpiar: {
      type: String,
      default: 'Limpiar'
    },
  },
  data() {
    return {
      filters: {},
      fechaDesde:null,
      fechaHasta:null,
      searchSitio: null,
      sitios: [],
      departamentos: [],
      municipios: [],
      distritos: [],
      timeId: null,
      estados: [],
      desdeDate: false,
      hastaDate: false,
      perfil: null,
    }
  },
  computed: {
    parseDesdeDate() {
      if (!this.fechaDesde) return null
      this.filters.fecha_desde = this.fechaDesde
      return this.moment(this.fechaDesde).format('DD/MM/YYYY')
    },
    parseHastaDate() {
      if (!this.fechaHasta) return null
      this.filters.fecha_hasta = this.fechaHasta
      return this.moment(this.fechaHasta).format('DD/MM/YYYY')
    }
  },
  methods: {
    buscarInforme() {
      this.$emit('buscar',{
        ...this.filters,
      })
    },
    async limpiarFilros() {
      this.filters = {}
      this.fechaDesde = null
      this.fechaHasta = null
      await this.executeClean()
    },
    buscarSitio() {
      this.timeId && clearTimeout(this.timeId)
      this.timeId = setTimeout(() => {
        this.getSelectSitio({nombre: this.searchSitio})
      }, 500)
    },
    async getSelectSitio(filters = {}) {
      try {
        const {data, status} = await this.services.proveedor.getSitio(filters);
        
        if (status === 200) {
          this.sitios = data.data.map((sitio) => ({
            id: sitio.id,
            nombre: `${sitio.codigo} - ${sitio.nombre}`,
          }));
        }
      } catch (error) {
        console.error("Error al obtener sitios", error);
      }
    },
    async getSelectDistrito(id_municipio) {
      try {
        const {data, status} = await this.services.proveedor.getDistrito({
          id_municipio,
        });

        if (status === 200) {
          this.distritos = data.data.map((distrito) => ({
            id: distrito.id,
            nombre: distrito.nombre,
          }));
        }
      } catch (error) {
        console.error("Error al obtener sitios", error);
      }
    },
    async getSelectMunicipio(id_departamento) {
      try {
        const {data, status} = await this.services.proveedor.getMunicipio({
          id_departamento,
        });

        if (status === 200) {
          this.municipios = data.data.map((municipio) => ({
            id: municipio.id,
            nombre: municipio.nombre,
          }));
        }
      } catch (error) {
        console.error("Error al obtener sitios", error);
      }
    },
    async getSelectDepartamento() {
      try {
        const {data, status} =
            await this.services.proveedor.getDepartamento();

        if (status === 200) {
          this.departamentos = data.data.map((departamento) => ({
            id: departamento.id,
            nombre: departamento.nombre,
          }));
        } else {
          console.error("Error al obtener sitios", data);
        }
      } catch (error) {
        console.error("Error al obtener sitios", error);
      }
    },
    async getEstadoReporte() {
      try {
        const {data: {data}, status} = await this.services.reportesMINED.getStateReportes();
        if (status === 200) {
          this.estados = data
        }
      } catch (e) {
        this.temporalAlert({
          show: true,
          message: 'Error al obtener los estados de los reportes',
          type: 'error'
        })
      }
    }
  },
  async created() {
    if (this.showEstado) {
      await this.getEstadoReporte()
    }
    await Promise.all([
      this.getSelectSitio(),
      this.getSelectDepartamento(),
    ]);

    this.perfil = JSON.parse(localStorage.getItem('userInfo'))
  }
}
</script>
