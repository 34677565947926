<template>
  <v-dialog
      v-model="dialog"
      persistent
      :max-width="anchoDialogo"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          v-bind="attrs"
          class="text-none"
          :class="pantallaPequena && 'mb-5'"
          v-on="on"
          depressed
          :outlined="btnDetonador.outlined"
          :color="btnDetonador.color ||`bg--color-primary`"
          :icon="btnDetonador.icon"
          :text="btnDetonador.text"
          :disabled="btnDetonador.disabled"
          :block="pantallaPequena"
          v-role="roles"
          large
      >
        <v-icon v-if="btnDetonador.icon">{{ btnDetonador.iconBtnNombre }}</v-icon>
        <p v-else class="pa-0 ma-0">{{ btnDetonador.nombre }}</p>
      </v-btn>
    </template>

    <v-card class="py-4 rounded-xl">
      <v-card-title class="d-flex justify-center mb-0">
        <p class="text--color-primary text-h5 font-weight-bold text-wrap text-center mb-0">{{ titulo }}</p>
      </v-card-title>
      <v-divider class="bg--color-primary" v-if="divider"/>
      <v-card-text class="mt-5">
        <p
            v-if="descripcion.length > 0"
            class="text-center text-body-1"
        >{{ descripcion }}</p>

        <!-- Slot para los formularios -->
        <slot name="formulario"></slot>

      </v-card-text>
      <v-card-actions :class="pantallaPequena ? 'flex-column' : 'flex-row'" class="d-flex justify-center">
        <v-btn
            color="primary"
            outlined
            class="pa-5 rounded-lg text-body-1 text-capitalize"
            :class="pantallaPequena ? 'mb-5' : 'mr-5'"
            text
            @click="cerrarDialog"
            depressed
            :block="pantallaPequena"
        >{{ btnNombreCancelar }}
        </v-btn>
        <v-btn
            color="primary"
            class="pa-5 rounded-lg text-capitalize"
            depressed
            :block="pantallaPequena"
            @click="confirmarDialog"
        >{{ btnNombreConfirmar }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AppDialogComponent",

  props: {
    btnDetonador: {
      nombre: {
        type: String,
        default: "Agregar"
      },
      color: {
        type: String,
        default: "bg--color-primary"
      },

      icon: {
        type: Boolean,
        default: false
      },

      iconBtnNombre: {
        type: String,
        default: "mdi-plus"
      },

      outlined: {
        type: Boolean,
        default: false
      },

      text: {
        type: Boolean,
        default: true
      },

      disabled: {
        type: Boolean,
        default: false
      },
    },
    titulo: {
      type: String,
      default: "Debes agregar un título al dialogo con el prop [titulo]"
    },

    descripcion: {
      type: String,
      default: ""
    },

    divider: {
      type: Boolean,
      default: false
    },

    btnNombreConfirmar: {
      type: String,
      default: "Aceptar"
    },

    btnNombreCancelar: {
      type: String,
      default: "Cancelar"
    },

    anchoDialogo: {
      type: String,
      default: "800"
    },

    roles: {
      type: Object,
      default: () => ({action: "hide", permission: ""})
    }
  },

  data() {
    return {
      dialog: false,
    }
  },

  watch: {
    dialog(val) {
      if (val) {
        this.$emit("abrirModal", val);
      }
    }
  },

  computed: {
    pantallaPequena() {
      return this.$vuetify.breakpoint.smAndDown;
    },

    mostrarNombreBtn() {
      return btnDetonador.icon ? this.btnDetonador.nombre : '';
    }
  },

  methods: {

    confirmarDialog() {
      this.$emit("confirmar");
    },

    cerrarDialog() {
      this.dialog = false;
      this.$emit("cerrar");
    }
  }

}
</script>

<style scoped>
hr[role="separator"] {
  min-height: 3px !important;
  max-width: 30% !important;
  margin: auto !important;
}

::v-deep .v-dialog {
  border-radius: 20px !important;
}
</style>
