<template>
  <v-container >
    <v-row justify="center">
      <h2 class="titulo">Consolidado de materiales</h2>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <h5>Fecha desde</h5>
        <v-menu
          ref="desdeDate"
          v-model="desdeDate"
          :close-on-content-click="true"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="parseDesdeDate"
              append-icon="mdi-calendar"
              outlined
              dense
              readonly
              v-bind="attrs"
              v-on="on"
              label="Fecha desde"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="fechaDesde"
            no-title
            scrollable
            @input="$refs.desdeDate.save(fechaDesde)"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <h5>Fecha hasta</h5>
        <v-menu
          ref="hastaDate"
          v-model="hastaDate"
          :close-on-content-click="true"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="parseHastaDate"
              append-icon="mdi-calendar"
              outlined
              dense
              readonly
              v-bind="attrs"
              v-on="on"
              label="Fecha hasta"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="fechaHasta"
            no-title
            scrollable
            @input="$refs.hastaDate.save(fechaHasta)"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
        class="d-flex align-center mb-2"
        style="gap: 5px"
      >
        <v-btn
          color="primary"
          class="text-capitalize pa-6"
          @click="buscarInforme"
        >
          Buscar
        </v-btn>

        <v-btn
          color="grey darken-1"
          outlined
          class="text-capitalize pa-6"
          @click="limpiarFilros"
        >
          Limpiar
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="space-between">
      <v-col cols="12" sm="6" md="8">
        <v-btn
          outlined
          class="custom-btn-select"
          style="
            margin-right: 10px;
            text-transform: none;
            font-size: smaller;
            margin-bottom: 10px;
          "
          @click="selectAll"
        >
          Seleccionar todos
        </v-btn>

        <v-btn
          v-if="$route.name === 'generacionActasSIP'"
          color="primary"
          class="custom-btn-primary"
          :disabled="selectedItems.length === 0"
          style="margin-right: 10px; text-transform: none; font-size: smaller"
          @click="showModal = true"
        >
          Acta de recepción
        </v-btn>

        <v-btn
          :disabled="selectedItems.length === 0"
          color="primary"
          class="custom-btn-primary custom-export"
          style="margin-right: 10px; text-transform: none; font-size: smaller"
          @click="getReporteEntrega"
        >
          Exportar
        </v-btn>
      </v-col>
      <v-col cols="12" sm="6" md="2" class="d-flex align-end">
        <p>Total de registros: {{ dataTable.totalRows }}</p>
      </v-col>
      <v-col cols="12" sm="6" md="2" class="d-flex align-end">
        <p>Total seleccionados: {{ selectedItems.length }}</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <app-table
          @refresh="setPagination"
          class="text-center"
          :headers="dataTable.headers"
          :items="dataTable.items"
          :set-page="dataTable.pagination.page"
          :total-rows="dataTable.totalRows"
        >
          <template #[`item.sitio`]="{ item }">
            {{ item.sitio.codigo }} - {{ item.sitio.nombre }}
          </template>

          <template #[`item.departamento`]="{ item }">
            {{ item.departamento.nombre }}
          </template>

          <template #[`item.municipio`]="{ item }">
            {{ item.municipio.nombre }}
          </template>

          <template #[`item.distrito`]="{ item }">
            {{ item.distrito.nombre }}
          </template>

          <template #[`item.estado`]="{ item }">
            <app-state-table
              :state="item.estado.id"
              :is-soli-state="true"
            ></app-state-table>
            <!--            <v-chip-->
            <!--                class="d-flex align-center justify-center"-->
            <!--                :color="item.activo ? 'green lighten-4' : 'red lighten-4'"-->
            <!--                :text-color="item.activo ? 'green darken-2' : 'red darken-2'"-->
            <!--            >-->
            <!--              {{ item.activo ? "Aprobado" : "En curso" }}-->
            <!--            </v-chip>-->
          </template>

          <template #[`item.fecha_instalacion`]="{ item }">
            {{
              item.fecha_instalacion &&
              moment(item.fecha_instalacion).format("DD/MM/YYYY")
            }}
          </template>

          <!-- <template #[`item.seleccionar`]="{ item }">
            <v-checkbox
              
            ></v-checkbox>
          </template> -->
          <template v-slot:item.seleccionar="{item }">
            <v-checkbox
                    v-model="selectedItems"
                    :value="item.id"
                    class="no-border-checkbox"
                  ></v-checkbox>

      </template>
        </app-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import appTable from "../../../components/AppTable.vue";
import FiltrosComponent from "@/components/FiltrosComponent.vue";
import moment from "moment";
import { required } from "vuelidate/lib/validators";
import AppStateTable from "@/components/AppStateTable.vue";

export default {
  components: {
    AppStateTable,
    appTable,
    FiltrosComponent,
  },

  data() {
    return {
      isAllSelected: false,
      fechaDesde: null,
      fechaHasta: null,
      filters: {
        fecha_desde: null,
        fecha_hasta: null,
      },
      desdeDate: false,
      hastaDate: false,
      selected: [ ],
      iframe: false,
      pdfUrl: "",
      formattedDate: "",
      showModal: false,
      showDatePicker: false,
      selectedDate: null,
      selectedTime: null,
      selectedItems: [],
      includeFiles: true,
      pdfRequest: {
        sitioPdf: [],
        fechaSolicitud: null,
        horaSolicitud: null,
      },
      estados: [],
      dataTable: {
        headers: [
          {
            text: "Seleccionar",
            value: "seleccionar",
            sortable: false,
            width: "10%",
            align: "center",
          },
          {
            text: "Sitio",
            value: "sitio",
            sortable: false,
            width: "25%",
            align: "center",
          },
          {
            text: "Departamento",
            value: "departamento",
            sortable: false,
            align: "center",
          },
          {
            text: "Municipio",
            value: "municipio",
            sortable: false,
            width: "17%",
            align: "center",
          },
          {
            text: "Distrito",
            value: "distrito",
            sortable: false,
            align: "center",
          },
          {
            text: "Fecha",
            value: "fecha_instalacion",
            sortable: false,
            align: "center",
          },
        ],
        items: [],
        pagination: {
          page: 1,
          per_page: 10,
        },
        totalRows: 0,
        contadorBusqueda: 0,
      },
    };
  },
  validations: {
    selectedDate: {
      required,
    },
    selectedTime: {
      required,
    },
  },
  computed: {
    parseDesdeDate() {
      if (!this.fechaDesde) return null;
      this.filters.fecha_desde = this.fechaDesde;
      return this.moment(this.fechaDesde).format("DD/MM/YYYY");
    },
    parseHastaDate() {
      if (!this.fechaHasta) return null;
      this.filters.fecha_hasta = this.fechaHasta;
      return this.moment(this.fechaHasta).format("DD/MM/YYYY");
    },
  },

  methods: {
    async handlerChangeCheckbox(id) {
      this.dataTable.items = this.dataTable.items.map((item) => {
        if (item.id === id) {
          item.seleccionar = !item.seleccionar;
        }
        return item;
      });
    },

    async limpiarFilros() {
      this.fechaDesde = null;
      this.fechaHasta = null;
      await this.getInformacionTabla();
    },

    async getReporteEntrega() {
      try {
        this.showLoader();

        const {data, status} =
          await this.services.consolidadoMateriales.getReporteConsolidado({
            ids: this.selectedItems,
            isAllSelected: this.isAllSelected ? 1 : 0,
          });


        if (status === 200) {
          const url = window.URL.createObjectURL(
            new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "consolidadoMateriales.xlsx"); // Nombre del archivo
          document.body.appendChild(link);
          link.click();

          // Limpiar el objeto URL
          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
        }
        this.hideLoader();
      } catch (e) {
        console.error("Error:", e);
        this.hideLoader();
        this.temporalAlert({
          show: true,
          message: "Error al obtener el reporte",
          type: "error",
        });
      }
    },

    async buscarInforme() {
      if (!this.filters.fecha_desde || !this.filters.fecha_hasta) {
        this.temporalAlert({
          show: true,
          message: "Por favor selecciona ambas fechas para filtrar.",
          type: "warning",
        });
        return;
      }

      // Verificar que fecha_desde no sea mayor que fecha_hasta
      if (
        new Date(this.filters.fecha_desde) > new Date(this.filters.fecha_hasta)
      ) {
        this.temporalAlert({
          show: true,
          message: "La fecha de inicio no puede ser mayor que la fecha final.",
          type: "error",
        });
        return;
      }

      await this.getInformacionTabla(this.filters);
    },

    selectAll() { 
      
      if (this.isAllSelected) {
        this.selectedItems = [];
        this.isAllSelected = false;
      } else {
        this.selectedItems = this.dataTable.items.map(item => item.id)
        this.isAllSelected = true;
      }
    },

    toggleSelection(item) {
      // Si el ítem ya está en los seleccionados, lo eliminamos
      const index = this.selectedItems.findIndex((i) => i.id === item.id);
      if (index > -1) {
        this.selectedItems.splice(index, 1);
      } else {
        // Si no está, lo agregamos
        this.selectedItems.push(item);
      }
    },
    //Empiezan los endpoints
    async getInformacionTabla(filters = {}) {

  try {
    this.showLoader();
    let params = {
      ...filters,
    };
    const { data, status } =
      await this.services.consolidadoMateriales.getConsolidadoMateriales(
        params
      );

    if (status === 200) {
      this.dataTable.items = data.data;
      this.dataTable.items = this.dataTable.items.map((item) => ({
        ...item,
        seleccionar: false,
      }));

      this.dataTable.totalRows = Number(data.pagination.total);

      if (this.isAllSelected) {
        const dataId = this.dataTable.items.map((item) => item.id);

        const existingIds = new Set(this.selectedItems);
        const uniqueIds = dataId.filter((id) => !existingIds.has(id));

        this.selectedItems = [
          ...this.selectedItems,
          ...uniqueIds,
        ];
      }
      this.hideLoader();
    }
  } catch (e) {
    this.hideLoader();
    this.temporalAlert({
      show: true,
      message: "Error al obtener la información",
      type: "error",
    });
  }
},


    async setPagination(params) {
      await this.getInformacionTabla(params);
    },
  },
  async mounted() {
    await this.getInformacionTabla();
  },
};
</script>

<style scoped lang="scss">
.custom-btn-select {
  border: 2px solid #1565c0 !important;
  min-height: 45px !important;
  color: #1565c0 !important;
  background-color: white !important;
}

.no-border-checkbox {
  ::v-deep .v-input__slot {
    border: none !important;
    display: flex;
    justify-content: center;
  }
}

::deep(.v-input__slot){
  border: none !important;
}

.custom-btn-primary {
  background-color: #1565c0;
  color: white;
  min-height: 45px;
}

.v-btn.custom-btn-primary:hover {
  background-color: #0d47a1;
}

.v-btn.custom-btn-select:hover {
  background-color: rgba(21, 101, 192, 0.1);
}

.titulo {
  color: #223e69;
}
h5 {
  padding-bottom: 0.3rem;
}
.custom-export {
  width: 10.5rem;
  margin-bottom: 10px;
}
::v-deep(.v-select.v-input--dense .v-select__selection--comma) {
  padding: 0 0 0 2px !important;
  margin: 5px 0 5px 5px !important;
  display: flex;
  align-items: flex-end;
}

::v-deep(.v-application--is-ltr .v-text-field .v-input__append-inner) {
  margin: 0 !important;
  padding: 0 !important;
}
::deep(.v-input__append-inner) {
  margin: 0 !important;
  padding: 0 !important;
}
.v-input__append-inner {
  margin: 0 !important;
  padding: 0 !important;
}
</style>
