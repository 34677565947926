import { http_client } from "../plugins/http_client";

const url = '/public';

const getConsolidadoMateriales = async (params = {}) =>
  await http_client(`${url}/instalacion/informes`, params);

const getReporteConsolidado = async (params = {}) => 
   await http_client(`${url}/instalacion/informesget`, 
    params,
    'get',
    {},
    true,
   'blob',
  );


export default {
  getConsolidadoMateriales,
  getReporteConsolidado,
};
