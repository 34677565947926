import Vue from "vue";
import auth from './auth.services'
import users from './user.services'
import roles from './roles.services'
import profiles from './profiles.services'
import typeRoles from './typeRoles.services'
import paths from './path.services'
import infoGeneral from './infoGeneral.services'
import catalogos from "@/services/catalogos.services";
import proveedor from "@/services/proveedor.services";
import redes from  "@/services/redes.services";
import fotografiasProveedorService from "./fotografiasProveedor.service";
import instalacionServices from "@/views/instalador/services/instalacion.services";
import reportesMINED from "@/views/reportes/reportesMINED/services/reportesMINED.services";
import seguimientoSIP from "@/views/reportes/seguimientoSIP/services/index";
import mapReport from "@/views/reportes/services/map.services";
import dashboard from "./dashboard.services";
import consolidadoMateriales from "./consolidadoMateriales.services";

Vue.prototype.services = {
    auth,
    users,
    roles,
    profiles,
    typeRoles,
    paths,
    infoGeneral,
    catalogos,
    proveedor,
    fotografiasProveedorService,
    redes,
    instalacionServices,
    reportesMINED,
    seguimientoSIP,
    mapReport,
    dashboard,
    consolidadoMateriales
}
