<template>

  <v-card class="primary">
    <!--    <v-card-text>-->
    <v-navigation-drawer
        :value="sideBar"
        fixed
        floating
        temporary
        @input="setSideBar($event)"
        class="custom-drawer"
    >
      <template v-slot:prepend class="rounded-xl">
        <!--      !sideBar-->
        <v-card color="#102c99" height="48px">
          <v-card-text class="white rounded-tr-xl" >
            <v-row class="rounded-xl " style="height: 48px">
              <v-col class="d-flex justify-end rounded-xl pa-0">
                <v-btn
                    icon
                    @click="setSideBar(!sideBar)"
                >
                  <v-icon>mdi-menu</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card elevation="2" class="ma-4 py-0">
          <v-card-title>
            <img
                :src="require(`@/assets/img/Logo2.png`)"
                width="95%"
                height="auto"
            />
          </v-card-title>
        </v-card>
      </template>

      <v-list dense nav>
      <span v-for="(item, i) in menu"
            :key="i">

        <v-list-group active-class="blueMinsal--text" v-if="item.childrens && item.childrens.length">
        <template v-slot:activator>
          <v-list-item-title class="text-wrap">
            {{ item.nombre }}
          </v-list-item-title>
        </template>
        <template #prependIcon>
          <img :src="require(`@/assets/img/${ item.icono }.png`)" class="menu-icon"/>
        </template>
          <div>
            <v-list-item v-for="(child, j) in item.childrens" :key="j" :to="child.uri" active-class="blueMinsal--text">
              <v-list-item-icon>
                <img :src="require(`@/assets/img/${ child.icono }.png`)" class="menu-icon"/>
              </v-list-item-icon>
              <v-list-item-title class="text-wrap">
                <span >{{ child.nombre }}</span>
              </v-list-item-title>
            </v-list-item>
          </div>
      </v-list-group>
        <v-list-item v-else :to="item.uri"
                     active-class=" blueMinsal--text">
          <v-tooltip right :disabled="!sideBar" color="blueMinsal" class="text-wrap">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-icon v-bind="attrs" v-on="on">
                <img :src="require(`@/assets/img/${ item.icono }.png`)" class="menu-icon"/>
              </v-list-item-icon>
            </template>
            <span>{{ item.nombre }}</span>
          </v-tooltip>

        <v-list-item-title class="text-wrap">
          {{ item.nombre }}
        </v-list-item-title>
        </v-list-item>
      </span>
      </v-list>
    </v-navigation-drawer>
    <!--    </v-card-text>-->
  </v-card>

</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: "AppAsideBar",
  props: {
    menu: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  methods: {
    ...mapMutations("utils", ["setSideBar"]),
    validationShowWithRol(id) {
      if (id === 1) {
        return {
          action: 'hide',
          permission: 'ROL_REDES_LISTAR_INFORMES'
        }
      }
      if (id === 2) {
        return {
          action: 'hide',
          permission: 'ROL_PROVEEDOR_LISTAR_INFORMES'
        }
      }
      if (id === 3) {
        return {
          action: 'hide',
          permission: 'ROL_INSTALACION_LISTAR_INFORMES'
        }
      }

      if (id === 4) {
        return {
          action: 'hide',
          permission: "ROL_ACTAS_SIP_LISTAR_INFORMES"
        }
      }

      if (id === 5) {
        return {
          action: 'hide',
          permission: "ROL_ACTAS_PROVEEDOR_LISTAR_INFORMES"
        }
      }

      if (id === 6) {
        return {
          action: 'hide',
          permission: "ROL_REPORTE_MINED_LISTAR_INFORMES"
        }
      }

      if (id === 7) {
        return {
          action: 'hide',
          permission: "ROL_SEGUIMIENTO_SIP_LISTAR_INFORMES"
        }
      }

      if (id === 8) {
        return {
          action: 'hide',
          permission: "ROL_LISTAR_MEDIO_CONEXION"
        }
      }

      if (id === 9) {
        return {
          action: 'hide',
          permission: "ROL_LISTAR_TIPO_MATERIAL"
        }
      }

      if (id === 10) {
        return {
          action: 'hide',
          permission: "ROL_LISTAR_UNIDAD_MEDIDA"
        }
      }
    },
  },
  computed: {
    ...mapState("utils", ["sideBar"]),
  },
};
</script>

<style scoped>
.menu-icon {
  width: 24px;
  height: 24px;
}
</style>
