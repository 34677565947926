<template>
  <v-container class="responsive-container">
    <v-row>
      <v-col cols="12" lg="6" sm="12" style="padding: 0% 0% 20px 0%;">
        <v-col style="padding: 0%;">
          <span class="text-h4 boldBlue--text"> {{ title }} </span>
        </v-col>
        <v-col style="padding: 0% 0% 10px 0%;;">
          <span class="text-h5 boldBlue--text"> {{ subtitle }} </span>
        </v-col>
        <v-col style="padding: 0%">
          <h3 class="boldBlue--text">{{ `${textCorrelativo}` }}</h3>
        </v-col>
      </v-col>
      <v-col cols="12" lg="6" sm="12" style="display: flex; justify-content: end; gap: 10px; padding: 0%; margin-bottom: 20px;">
        <v-btn class="text-no-style grey--text text--darken-2 elevation-0 pa-6"
          color="grey lighten-2" @click="$router.go(-1)">
          Regresar
        </v-btn>
        <v-btn v-if="!hiddenBtns" class="text-no-style elevation-0 pa-6" color="lightBlue" outlined
          @click="$emit('guardar')">
          Guardar
        </v-btn>
        <v-btn v-if="!hiddenBtns" class="text-no-style white--text elevation-0 pa-6"
          color="lightBlue" :disabled="disabledBtnEnviar" @click="$emit('enviar')">
          Enviar
        </v-btn>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "HeaderInformComponent",

  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    disabledBtnEnviar: {
      type: Boolean,
      default: true,
    },
    hiddenBtns: {
      type: Boolean,
      default: true,
    },
    correlativo: {
      type: String,
      default: "",
    }
  },
  computed: {
    textCorrelativo() {
      if (this.correlativo === "") return ""
      return `Informe de conectividad ${this.correlativo}`
    }
  },
  watch: {
    disabledBtnEnviar: {
      handler(newVal) {
        this.disabledBtnEnviar = newVal;
      },
      deep: true,
      inmediate: true
    }
  }
};
</script>
<style lang="scss" scoped>
.margin-md {
  padding-left: 8.5%;
}

.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn-outlined) {
  background-color: rgba(55, 88, 249, 0.5) !important;
  color: white !important;
}

.responsive-container {
  width: 100%;
}

@media (min-width: 1280px) {
  .responsive-container {
    width: 80%;
  }
}
</style>
