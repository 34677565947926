import Vue from "vue";
import VueRouter from "vue-router";
import jwtDecode from "jwt-decode";
import {proveedorRoutes} from "@/views/proveedor/router";
import {instaladorRoutes} from "@/views/instalador/router";
import {redesRoutes} from "@/views/redes/router";
import {actasRouter} from "@/views/generacion_actas/router";
import {reportesRouter} from "@/views/reportes/router";
import {consolidadoMaterialesRouter} from "../views/generacion_actas/views/consolidadoMateriales.vue";

// import rolesRouter from "../views/roles/rolesRouter";
// import profilesRouter from "../views/profiles/profilesRouter";
// import usersRouter from "../views/users/userRouter";
// import pathsRouter from "../views/paths/pathRouter";

// import {infreaestructuraRoute} from "@/views/infraestructura/router/router";
// import {tipoSitiosRoutes} from "@/views/tipos_sitios/router";
// import {mediosConexionRouter} from "@/views/medios_conexion/router/router";
// import {tipoArchivosRouter} from "@/views/tipo_archivo/router";
// import {tipoMaterialRouter} from "@/views/tipo_materiales_equipos/router/router";
// import {unidadMedidRouter} from "@/views/unidades_medida/router/router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import(/* webpackChunkName: "layout" */ "../views/layout"),
    children: [
      {
        path: "/",
        name: "dashboard",
        component: () =>
            import(/* webpackChunkName: "profiles" */ "../views/Graficas/Dashboard.vue"),
      },
      {
        path: "/consolidado-materiales",
        name: "consolidado-materiales",
        component: () =>
            import(/* webpackChunkName: "profiles" */ "../views/generacion_actas/views/consolidadoMateriales.vue"),
      },
      // {
      //   path: "index",
      //   name: "dashboard",
      //   component: () =>
      //       import(/* webpackChunkName: "profiles" */ "../views/Graficas/Dashboard.vue"),
      // },
      // {
      //     path: "profile",
      //     name: "profile",
      //     component: () =>
      //         import(/* webpackChunkName: "profiles" */ "../views/profile"),
      // },

      ...reportesRouter,
      ...actasRouter,
      ...proveedorRoutes,
      ...instaladorRoutes,
      ...redesRoutes,
      // ...rolesRouter,
      // ...profilesRouter,
      // ...usersRouter,
      // ...pathsRouter,
      // ...infreaestructuraRoute,
      // ...tipoSitiosRoutes,
      // ...mediosConexionRouter,
      // ...tipoArchivosRouter,
      // ...tipoMaterialRouter,
      // ...unidadMedidRouter
      //   ...sitiosRoutes,
      //   ...tiposFotografiaRoutes,
      //   ...tipoSitiosRoutes,
      //   ...tipoArchivoRoutes,
      // {
      //   path: "/security",
      //   name: "security",
      //   component: () => import(/* webpackChunkName: "qr" */ "../views/auth/security"),
      // },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import(/* webpackChunkName: "login" */ "../views/auth/login"),
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () =>
        import(
            /* webpackChunkName: "recuperarPassword" */ "../views/auth/recoverPassword"
            ),
  },
  {
    path: "/reset-password/:id",
    name: "reset-password",
    component: () =>
        import(/* webpackChunkName: "resetPassword" */ "../views/auth/resetPassword"),
  },
  {
    path: "/verify-mail/:token",
    name: "verifyMail",
    component: () =>
        import(/* webpackChunkName: "login" */ "../views/auth/verifyMail"),
  },
  {
    path: "/forbidden",
    name: "Forbidden",
    component: () =>
        import(/* webpackChunkName: "forbidden" */ "../views/forbidden"),
  },
  {
    path: "/autenticacionqr",
    name: "2fa",
    component: () => import(/* webpackChunkName: "qr" */ "../views/auth/2fa"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem("token");

  if (to.fullPath === "/") {
    if (!token) {
      return next("/login");
    }
  }

  if (to.meta.rol) {
    if (!token) {
      return next("/login");
    }

    try {
      const decodedToken = jwtDecode(token);
      const roles = decodedToken.roles;

      if(Array.isArray(to.meta.rol)) {
        const hasAccess = to.meta.rol.some(role => roles && roles.includes(role));
        if (!hasAccess) {
          return next("/forbidden");
        }
      } else {
        if (!roles || !roles.includes(to.meta.rol)) {
          return next("/forbidden");
        }
      }
    } catch (error) {
      return next("/login");
    }
  }

  next();


//     if (await Vue.prototype.canNext(to)) {
//         next();
//     } else {
//         next("/forbidden");
//     }
});

export default router;
