export const instaladorRoutes = [
  {
    path: "/equipo-instalador",
    name: "equipo-instalador",
    meta: { rol: "ROL_INSTALACION_LISTAR_INFORMES" },
    component: () =>
        import(/* webpackChunkName: "equipo-instalador" */ "../index.vue"),
  },
  {
    path: "/detalle-informe-instalador/:id",
    name: "detalleInformeInstalador",
    eta: { rol: ["ROL_INSTALACION_VER_DETALLE_GENERAL_INFORME", "ROL_MINED_VER_DETALLE_GENERAL_INFORME"] },
    component: () =>
        import(/* webpackChunkName: "equipo-instalador" */ "../views/FormInformeView.vue"),
  },

]
