<template>
  <v-container fluid>
    <v-row>
      <v-col :cols="xs" :md="md" :lg="lg" :class="classCol">
        <v-card
            class="drop"
            :class="{ 'error-drop': error }"
            v-if="!evidenciaLocal.length"
        >
          <div class="drop-informacion" @dragover.prevent @drop="onDrop">
            <div class="titleAndIcon-style">
              <div>
                <v-icon x-large class="material-symbols-outlined">{{
                    icono
                  }}
                </v-icon>
              </div>
              <div>
                <span class="drop-titulo">{{ titulo }}</span>
              </div>
            </div>
            <p class="drop-subtitulo mt-2 mb-2">{{ subtitulo }}</p>
            <p class="drop-subtitulo">{{ subtitulo2 }}</p>
            <div class="archivo-boton">
              <button class="btn-select">Seleccionar Archivo</button>
            </div>
          </div>
          <input :disabled="disabled" :readonly="disabled" type="file" :accept="allowedTypes.toString()"
                 @change="onChange" ref="fileInput"/>
        </v-card>
        <p class="red--text">{{ mensaje }}</p>

      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import VuePdf from "vue-pdf";

export default {
  components: {
    VuePdf,
  },
  props: {
    icono: {
      type: String,
      default: "mdi-attachment",
    },
    titulo: {
      type: String,
      default: "Seleccione archivo, 5mb como máximo, formato PNG,JPG",
    },
    subtitulo: {
      type: String,
      default: "   ",
    },
    subtitulo2: {
      type: String,
      default: "",
    },
    evidencia: {
      type: Array,
      default: () => [],
    },
    imageArray: {
      type: Array,
      default: () => ([]),
    },
    allowedTypes: {
      type: Array,
      default: () => ["image/jpeg", "image/png", "application/pdf"],
    },
    evidenciado: {
      type: Object,
      default: ()=>({}),
    },
    onRemove: Function,
    idInforme: Number,
    idTipoArchivo: Number,
    disabled: {
      type: Boolean,
      default: false,
    },
    classCol: {
      type: String,
      default: "mx-auto",
    },
    xs: {
      type: Number,
      default: 12,
    },
    md: {
      type: Number,
      default: 8,
    },
    lg: {
      type: Number,
      default: 6,
    },
  },
  data() {
    return {
      error: false,
      mensaje: "",
      evidenciaLocal: this.evidenciado ? [this.evidenciado] : [],
      showButtons: false,
      dialog: false,
    };
  },
  watch: {
    evidenciado(newVal) {
      if (newVal) {
        this.evidenciaLocal = [newVal];
      }
    },
    imageArray(newVal) {
      if (newVal.length) {
        this.evidenciaLocal = newVal;
      }
    },
    evidencia: {
      immediate: true,
      handler(newVal) {
        this.evidenciaLocal = newVal.map((file) => ({
          ...file,
          url: URL.createObjectURL(file),
        }));
      },
      deep: true
    },
  },
  methods: {
    onDrop(event) {
      const files = event.dataTransfer.files;
      this.handleFiles(files);
    },
    onChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.handleFiles([file]);
      }
    },
    handleFiles(files) {
      if (files.length > 1) {
        this.error = true;
        this.mensaje = "Solo se puede subir un archivo a la vez.";
        return;
      }

      const file = files[0];
      const isImageOrPdf = this.isValidFileType(file);
      const isValidSize = this.isValidFileSize(file);

      if (!isImageOrPdf) {
        this.error = true;
        this.mensaje = `Formato no permitido: ${file.name}`;
        return;
      }

      if (!isValidSize) {
        this.error = true;
        this.mensaje = `El archivo ${file.name} excede el tamaño máximo permitido de 5MB.`;
        return;
      }

      this.error = false;
      this.mensaje = "";

      const fileUrl = URL.createObjectURL(file);
      let isImg = false;
      if (file.type.includes('image')) {
        isImg = true;
      }
      this.evidenciaLocal = [{file, url: fileUrl, name: file.name, isImg: isImg}];
      this.$emit(
          "update:evidencia",
          this.evidenciaLocal.map((file) => file.file)
      );
    },

    removeFile() {
      this.evidenciaLocal = [];
      // this.$emit("update:evidencia", []);
      this.showButtons = false;
      this.onRemove(this.idInforme, this.idTipoArchivo);  // Llamar al método pasado como prop
    },
    viewFile() {
      this.dialog = true;
    },
    isValidFileType(file) {
      const allowedTypes = this.allowedTypes;
      return allowedTypes.includes(file.type);
    },
    isValidFileSize(file) {
      const maxSize = 5 * 1024 * 1024; // 5MB
      return file.size <= maxSize;
    },

    isImage(file) {
      return (typeof file?.url === 'string' && file?.url.startsWith('blob:') && file?.isImg) || (file?.url.startsWith('data:image') && file?.isImg);


    },
  },
  computed: {
    widthPreview() {
      return this.previewWidth ? `${this.previewWidth}px` : "100%";
    },
  },
};
</script>

<style scoped>
.previews {
  display: flex;
  justify-content: center;
  position: relative;
  height: 350%;
  width: 260px;
  margin-bottom: 10px;
}

.preview-item {
  width: 100%;
  height: 400px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: unset;
  border-radius: 15px;

}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;

}

.preview-pdf-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.preview-pdf {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
}

.button-remove {
  margin: 10px;
  color: red;
  background-color: tomato;
}

.button-view {
  margin: 10px;

  color: blue;
  background-color: lightblue;
}

.full-image,
.full-pdf {
  width: 100%;
  height: auto;
}

.drop {
  width: 100%;
  height: 200px;
  position: relative;
  border: 1px dashed blue;
  border-radius: 15px;
}

.drop:hover {
  border: 2px dashed blue;
}

.drop-informacion {
  color: blue;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.drop-titulo {
  color: blue;
  /* font-family:Arial, Helvetica, sans-serif */
}

.drop input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.drop-subtitulo {
  font-size: 0.8em;
}

/* imagen */
.v-img {
  transition: opacity 0.4s ease-in-out;
}

.on-hover {
  background: black !important;
  transition: 0.2s;
  opacity: 0.8;
}

.show-btns {
  color: red !important;
  transform: translate(0, 50%);
}

.error-drop {
  border: 2px dashed red !important;
  border-radius: 15px;
}

.drag-and-drop-reduced-margin .mt-10 {
  margin-top: 0px !important; /* Ajusta este valor según sea necesario */
}

.hover-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
}

.icono {
  color: blue;
}

.btn-select {
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  background-color: #f8f8f9;
  color: black;
  cursor: pointer;
  border-radius: 4px;
}
</style>
